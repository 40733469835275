<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-single-02 "></i> Validasi Pendaftaran
      </h6>
    </h1>
    
    <base-header class="pb-6">
    <b-row>
      <b-col xl="7" md="7">
      </b-col>
    </b-row>
    </base-header>

    <b-container v-if="isLoading">
      <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--6" v-if="!isLoading">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                <base-button
                  size="md"
                  type="primary"
                  @click="handleDownload"
                >
                  Download
                </base-button>
              </b-col>
            </b-row>
            </template>
            <b-col cols="12" class="mb-3 d-flex  flex-wrap">
               <div style="display: flex;flex-direction: column;">
                 <label><small>Status Pendaftaran</small></label>
                 <el-select class="select-primary pagination-select mr-3" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
               </div>
              <div style="display: flex;flex-direction: column;">
                <label><small>Jenis Pendaftaran</small></label>
                <el-select class="select-primary pagination-select" v-model="filterJenisPendaftaran"  placeholder="Jenis Pendaftaran">
                <el-option
                    class="select-primary"
                    v-for="item in selectOptionRegister"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                >
                </el-option>
                </el-select>
              </div>
            </b-col>
            <ValidasiPendaftaran :tableData="listData"/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from '../../../api/base_url';
import { Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import ValidasiPendaftaran from '../../Tables/AdminTable/AdminPendaftaranSparing.vue';
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";



export default {
components: {
  BaseHeader,
  DataSparingChart,
  [Select.name]: Select,
  [Option.name]: Option,
  ValidasiPendaftaran
},
mounted() {
},
data() {
  return {
    isLoading:true,
    listData: [{}],
    filterJenisPendaftaran : "SP",
    filterStatusPendaftaran: "ALL",
    selectOptionRegister: [
        {
          val:"",
          name:"Semua"
        },
        {
          val:"SP",
          name:"Sparing"
        },
        {
          val:"PG",
          name:"Pelaporan Progres"
        },
        {
          val:"RP",
          name:"Pemanfaatan Kembali"
        },
        {
          val:"CT",
          name:"Uji Konek"
        },
    ],
    selectOptionStatus: [
        {
          val:"ALL",
          name:"Semua"
        },
        {
          val:"WV",
          name:"Waiting"
        },
        {
          val:"VA",
          name:"Approved"
        },
        {
          val:"VR",
          name:"Rejected"
        },
        {
          val:"WC",
          name:"Waiting Change"
        },
    ],
    keyword:"",
    provideSelect: {
      provinsi:[],
      kabupatenKota:[],
      daftarPerusahaan:[],
      tmp:[],
      uploadChoosen:null
    },
    userInput: {
      jenisIndustri:"",
      provinsi:"",
      kabupaten:"",
      namaIndustri:"",
      tanggal:""
    },
  };
},
mounted() {
  this.getData()
},
methods: {
  getBaseOnJenisPendaftaran(value) {
    console.log(value);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get(`web/master/validation/sparing/list?status=${value}` , {headers})
      .then(({data:content}) => {
          this.listData = content.data
      })
      .catch((err) => {
        console.log(err);
    })
    
  },
  getData() {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    API.get('web/master/admin/sparing/list' , {headers})
      .then(({data:content}) => {
          this.listData = content.data
          this.isLoading = false
      })
      .catch((err) => {
        console.log(err);
      })
  },
  search() {

  },
  handleDownload() {
    Swal.fire({
      title: 'Download  data sebagai',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'CSV',
      denyButtonText: `PDF`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Download PDF', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Download CSV', '', 'info')
      }
    })
  }
},
};
</script>


<style lang="scss">
#dashboard {
.m-0 {
  .form-group {
    margin: 0;
    .valid-feedback {
      margin: 0;
    }
  }
}
}
.header-desc {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
color: #29664a;
font-weight: 600;
}
.bmal {
font-size: 12px;
}
</style>

  
  
  